
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';

import { useCreateBlock, useUpdateBlock, useBlock } from '@/composables/api';
import { PartialBlock, BlockType, PageType, BlockBox } from '@/interfaces/Block';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialBlock = {
  title: '',
  titleCn: '',
  description: '',
  type: BlockType.ADVERTISEMENT,
  page: PageType.HOME,
  isBox: BlockBox.NO,
  sort: 0
};

const rules: Rules = {
  title: [
    {
      required: true
    }
  ],
  type: [
    {
      required: true
    }
  ],
  page: [
    {
      required: false
    }
  ],
  isBox: [
    {
      required: false
    }
  ],
  sort: [
    {
      required: true
    }
  ]
};

const typeOptions = [
  {
    label: 'Video',
    value: 'video'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    label: 'Advertisement',
    value: 'advertisement'
  },
  {
    label: 'Photo',
    value: 'photo'
  }
];

const pageOptions = [
  {
    label: 'Home',
    value: 'home'
  },
  {
    label: 'Join',
    value: 'join'
  },
  {
    label: 'Video',
    value: 'video'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    lobel: 'News',
    value: 'news'
  },
  {
    label: 'Site',
    value: 'site'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const page = useRoute().query.page;
    const blockId = useRoute().params.id as string;
    const router = useRouter();
    const { t } = useI18n();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateBlock();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateBlock();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useBlock({ blockId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { blockId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-blocks'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    name: 'list-blocks'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      IntBoolean,
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      typeOptions,
      pageOptions,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
